<template>
    <div class="d-flex justify-center align-center h-full w-full">
        <div style="width: 1129px; height: 677px;">
            <FlowMap></FlowMap>
        </div>
    </div>
</template>

<script>
import FlowMap from '../../components/Scene/FlowMap.vue';
export default {
    components: { FlowMap },
}
</script>

<style lang="scss" scoped></style>