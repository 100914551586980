<template>
    <div class="h-full w-full">
        <div id="map" ref="mapContainer" :style="mapStyle"></div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: "bingdong",
        },
    },
    data() {
        return {
            chart: null,
            chartOption: {
                title: {
                    text: '十六铺码头-冰冻',
                    left: 'center',
                    textStyle: {
                        fontSize: 24
                    },
                    top: '4%'
                },
                tooltip: {},
                geo: {
                    left: '2%',
                    right: '3%',
                    tooltip: {
                        show: true,
                        formatter: function (param) {
                            return `经度:${param.data[0]}<br/>纬度:${param.data[1]}<br/>文本:${param.data[3]}`
                        }
                    },
                    map: this.type,
                    roam: true
                },
                series: {
                    type: 'scatter',
                    coordinateSystem: 'geo',
                    geoIndex: 0,
                    symbolSize: function (params) {
                        return params[2];
                    },
                    symbol: 'path://M512 1024C229.218462 1024 0 794.781538 0 512S229.218462 0 512 0s512 229.218462 512 512-229.218462 512-512 512z m-114.176-310.941538a53.326769 53.326769 0 0 0 75.421538 0l323.347693-323.347693A53.326769 53.326769 0 0 0 721.132308 314.289231L433.230769 597.582769l-128.866461-128.827077a53.326769 53.326769 0 1 0-75.421539 75.421539l168.881231 168.881231z',
                    itemStyle: {
                        color: '#3FB056',
                    },
                    encode: {
                        tooltip: 2
                    },
                    data: [
                        [550, 40, 60, '新建场景发布'],
                        [800, 70, 60, '生成任务并发送到码头'],
                        [1120, 33, 60, '站长收到响应措施列表'],
                        [1430, 33, 60, '组织落实相应措施'],
                        [1430, 420, 60, '落实后提交'],
                        [1030, 340, 60, '升级为红灯'],
                        [680, 310, 60, '收到超时提醒'],
                        [1710, 460, 60, '全部落实转为绿灯'],
                    ]
                }
            }
        }
    },
    computed: {
        mapStyle() {
            let obj = {
                width: "100%",
                height: '100%'
            };
            return obj;
        },
    },
    mounted() {
        var that = this;
        this.$nextTick(() => {
            console.log("FlowMap: init echarts");
            that.chart = that.$echarts.init(that.$refs.mapContainer);
            window.onresize = function () {
                console.log("do resize");
                that.chart.resize();
            };
            that.chart.setOption(that.chartOption, false);
            // this.chart.on("click", this.onClick);
        });
    },
}
</script>

<style lang="scss" scoped></style>