import { render, staticRenderFns } from "./FlowMap.vue?vue&type=template&id=635af420&scoped=true&"
import script from "./FlowMap.vue?vue&type=script&lang=js&"
export * from "./FlowMap.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "635af420",
  null
  
)

export default component.exports